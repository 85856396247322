import React, { useContext, useEffect } from "react";
import SEO from "../components/seo"
import { navigate } from "gatsby"
import {
  GlobalStateContext
} from "../context/GlobalContextProvider"

import { Layout } from 'antd';
const { Content } = Layout;

const IndexPage = ({ data }) => {
  const state = useContext(GlobalStateContext)
  const routeUser = () => {
    const route = async () => {
      if (state.auth_state) {
        if (state.role === "operator") {
          await navigate('/bookings')
        }
        else {
          await navigate('/users')
        }
      } else {
        await navigate('/login')
      }
    }
    route();
  }
  useEffect(routeUser, [state.auth_state]);
  return (
    <Content className="center" style={{ width: "100vw", height: "100vh" }}>
      <SEO title="Loading" />
    </Content>
  )
}
export default IndexPage;
